import React from "react"

import Layout from "../components/layout"

const Privacy = () => {
  return (
    <Layout>
      <main role="main" className="bg-white">
        <div style={{ backgroundColor: "#e7e7e7" }} className="relative">
          <img
            src="/images/privacy.jpeg"
            className="db center"
            style={{ width: "64rem" }}
            alt=""
          />
        </div>

        <div className="mw7 center pa4">
          <h2 className="f2 lato fw4 mv4">Privacy Policy</h2>
          <h3 className="f3 lato fw4 mv2">Titolare del Trattamento dei Dati</h3>
          <p className="f4 lato fw4">Time2Client S.r.l.</p>

          <h3 className="f3 lato fw4 mv2">Tipologie di Dati raccolti</h3>
          <p className="f4 lato fw4">
            Fra i Dati Personali raccolti da questa Applicazione, in modo
            autonomo o tramite terze parti, ci sono: nome, cognome, sesso, data
            di nascita, numero di telefono, indirizzo, email, password, ragione
            sociale, Codice Fiscale, nazione, provincia, città, indirizzo di
            fatturazione, numero civico, dati relativi al punto vendita e varie
            tipologie di Dati.
          </p>
          <p className="f4 lato fw4">
            Fra i Dati Personali raccolti da questa Applicazione, in modo
            autonomo o tramite terze parti, ci sono: nome, cognome, sesso, data
            di nascita, numero di telefono, indirizzo, email, password, ragione
            sociale, Codice Fiscale, nazione, provincia, città, indirizzo di
            fatturazione, numero civico, dati relativi al punto vendita e varie
            tipologie di Dati.
          </p>
          <p className="f4 lato fw4">
            L'Utente si assume la responsabilità dei Dati Personali di terzi
            ottenuti, pubblicati o condivisi mediante questa Applicazione e
            garantisce di avere il diritto di comunicarli o diffonderli,
            liberando il Titolare da qualsiasi responsabilità verso terzi.
          </p>

          <h3 className="f3 lato fw4 mv2">
            Modalità e luogo del trattamento dei Dati raccolti
          </h3>
          <p className="f4 lato fw4">
            Il Titolare adotta le opportune misure di sicurezza volte ad
            impedire l’accesso, la divulgazione, la modifica o la distruzione
            non autorizzate dei Dati Personali. Il trattamento viene effettuato
            mediante strumenti informatici e/o telematici, con modalità
            organizzative e con logiche strettamente correlate alle finalità
            indicate. Oltre al Titolare, in alcuni casi, potrebbero avere
            accesso ai Dati altri soggetti coinvolti nell’organizzazione di
            questa Applicazione (personale amministrativo, commerciale,
            marketing, legali, amministratori di sistema) ovvero soggetti
            esterni (come fornitori di servizi tecnici terzi, corrieri postali,
            hosting provider, società informatiche, agenzie di comunicazione)
            nominati anche, se necessario, Responsabili del Trattamento da parte
            del Titolare. L’elenco aggiornato dei Responsabili potrà sempre
            essere richiesto al Titolare del Trattamento.
          </p>

          <h3 className="f3 lato fw4 mv2">Base giuridica del trattamento</h3>
          <p className="f4 lato fw4">
            Il Titolare tratta Dati Personali relativi all’Utente in caso
            sussista una delle seguenti condizioni:
          </p>
          <ul className="f4 lato list">
            <li className="f4 lato pv2 lh-title">
              - l’Utente ha prestato il consenso per una o più finalità
              specifiche; Nota: in alcuni ordinamenti il Titolare può essere
              autorizzato a trattare Dati Personali senza che debba sussistere
              il consenso dell’Utente o un’altra delle basi giuridiche
              specificate di seguito, fino a quando l’Utente non si opponga
              (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile
              qualora il trattamento di Dati Personali sia regolato dalla
              legislazione europea in materia di protezione dei Dati Personali;
            </li>
            <li className="f4 lato pv2 lh-title">
              - il trattamento è necessario all'esecuzione di un contratto con
              l’Utente e/o all'esecuzione di misure precontrattuali;
            </li>
            <li className="f4 lato pv2 lh-title">
              - il trattamento è necessario per adempiere un obbligo legale al
              quale è soggetto il Titolare;
            </li>
            <li className="f4 lato pv2 lh-title">
              - il trattamento è necessario per l'esecuzione di un compito di
              interesse pubblico o per l'esercizio di pubblici poteri di cui è
              investito il Titolare;
            </li>
            <li className="f4 lato pv2 lh-title">
              - il trattamento è necessario per il perseguimento del legittimo
              interesse del Titolare o di terzi.
            </li>
          </ul>
          <p className="f4 lato fw4">
            E’ comunque sempre possibile richiedere al Titolare di chiarire la
            concreta base giuridica di ciascun trattamento ed in particolare di
            specificare se il trattamento sia basato sulla legge, previsto da un
            contratto o necessario per concludere un contratto.
          </p>

          <h3 className="f3 lato fw4 mv2">Luogo</h3>
          <p className="f4 lato fw4">
            I Dati sono trattati presso le sedi operative del Titolare ed in
            ogni altro luogo in cui le parti coinvolte nel trattamento siano
            localizzate. Per ulteriori informazioni, contatta il Titolare. I
            Dati Personali dell’Utente potrebbero essere trasferiti in un paese
            diverso da quello in cui l’Utente si trova. Per ottenere ulteriori
            informazioni sul luogo del trattamento l’Utente può fare riferimento
            alla sezione relativa ai dettagli sul trattamento dei Dati
            Personali.
          </p>
          <p className="f4 lato fw4">
            L’Utente ha diritto a ottenere informazioni in merito alla base
            giuridica del trasferimento di Dati al di fuori dell’Unione Europea
            o ad un’organizzazione internazionale di diritto internazionale
            pubblico o costituita da due o più paesi, come ad esempio l’ONU,
            nonché in merito alle misure di sicurezza adottate dal Titolare per
            proteggere i Dati.
          </p>
          <p className="f4 lato fw4">
            Qualora abbia luogo uno dei trasferimenti appena descritti, l’Utente
            può fare riferimento alle rispettive sezioni di questo documento o
            chiedere informazioni al Titolare contattandolo agli estremi
            riportati in apertura.
          </p>

          <h3 className="f3 lato fw4 mv2">Periodo di conservazione</h3>
          <p className="f4 lato fw4">
            I Dati sono trattati e conservati per il tempo richiesto dalle
            finalità per le quali sono stati raccolti.
          </p>
          <p className="f4 lato fw4">Pertanto:</p>
          <ul className="f4 lato list">
            <li className="f4 lato pv2 lh-title">
              - i Dati Personali raccolti per scopi collegati all’esecuzione di
              un contratto tra il Titolare e l’Utente saranno trattenuti sino a
              quando sia completata l’esecuzione di tale contratto.{" "}
            </li>
            <li className="f4 lato pv2 lh-title">
              - i Dati Personali raccolti per finalità riconducibili
              all’interesse legittimo del Titolare saranno trattenuti sino al
              soddisfacimento di tale interesse. L’Utente può ottenere ulteriori
              informazioni in merito all’interesse legittimo perseguito dal
              Titolare nelle relative sezioni di questo documento o contattando
              il Titolare.
            </li>
          </ul>
          <p></p>
          <p className="f4 lato fw4">
            Quando il trattamento è basato sul consenso dell’Utente, il Titolare
            può conservare i Dati Personali più a lungo sino a quando detto
            consenso non venga revocato. Inoltre il Titolare potrebbe essere
            obbligato a conservare i Dati Personali per un periodo più lungo in
            ottemperanza ad un obbligo di legge o per ordine di un’autorità.
          </p>
          <p className="f4 lato fw4">
            Al termine del periodo di conservazioni i Dati Personali saranno
            cancellati. Pertanto, allo spirare di tale termine il diritto di
            accesso, cancellazione, rettificazione ed il diritto alla
            portabilità dei Dati non potranno più essere esercitati.
          </p>

          <h3 className="f3 lato fw4 mv2">
            Finalità del Trattamento dei Dati raccolti
          </h3>
          <p className="f4 lato fw4">
            I Dati dell’Utente sono raccolti per consentire al Titolare di
            fornire i propri Servizi, così come per le seguenti finalità:
            Registrazione ed autenticazione e Trasferimento di Dati al di fuori
            della UE.
          </p>
          <p className="f4 lato fw4">
            Per ottenere ulteriori informazioni dettagliate sulle finalità del
            trattamento e sui Dati Personali concretamente rilevanti per
            ciascuna finalità, l’Utente può fare riferimento alle relative
            sezioni di questo documento.
          </p>

          <h3 className="f3 lato fw4 mv2">Diritti dell’Utente</h3>
          <p className="f4 lato fw4">
            Gli Utenti possono esercitare determinati diritti con riferimento ai
            Dati trattati dal Titolare.
          </p>
          <p className="f4 lato fw4">
            In particolare, l’Utente ha il diritto di:
          </p>
          <ul className="f4 lato list">
            <li className="f4 lato pv2 lh-title">
              - revocare il consenso in ogni momento. L’Utente può revocare il
              consenso al trattamento dei propri Dati Personali precedentemente
              espresso.
            </li>
            <li className="f4 lato pv2 lh-title">
              - opporsi al trattamento dei propri Dati. L’Utente può opporsi al
              trattamento dei propri Dati quando esso avviene su una base
              giuridica diversa dal consenso. Ulteriori dettagli sul diritto di
              opposizione sono indicati nella sezione sottostante.
            </li>
            <li className="f4 lato pv2 lh-title">
              - accedere ai propri Dati. L’Utente ha diritto ad ottenere
              informazioni sui Dati trattati dal Titolare, su determinati
              aspetti del trattamento ed a ricevere una copia dei Dati trattati.
            </li>
            <li className="f4 lato pv2 lh-title">
              - verificare e chiedere la rettificazione. L’Utente può verificare
              la correttezza dei propri Dati e richiederne l’aggiornamento o la
              correzione.
            </li>
            <li className="f4 lato pv2 lh-title">
              - ottenere la limitazione del trattamento. Quando ricorrono
              determinate condizioni, l’Utente può richiedere la limitazione del
              trattamento dei propri Dati. In tal caso il Titolare non tratterà
              i Dati per alcun altro scopo se non la loro conservazione.
            </li>
            <li className="f4 lato pv2 lh-title">
              - ottenere la limitazione del trattamento. Quando ricorrono
              determinate condizioni, l’Utente può richiedere la limitazione del
              trattamento dei propri Dati. In tal caso il Titolare non tratterà
              i Dati per alcun altro scopo se non la loro conservazione.
            </li>
            <li className="f4 lato pv2 lh-title">
              - ricevere i propri Dati o farli trasferire ad altro titolare.
              L’Utente ha diritto di ricevere i propri Dati in formato
              strutturato, di uso comune e leggibile da dispositivo automatico
              e, ove tecnicamente fattibile, di ottenerne il trasferimento senza
              ostacoli ad un altro titolare. Questa disposizione è applicabile
              quando i Dati sono trattati con strumenti automatizzati ed il
              trattamento è basato sul consenso dell’Utente, su un contratto di
              cui l’Utente è parte o su misure contrattuali ad esso connesse.
            </li>
            <li className="f4 lato pv2 lh-title">
              - proporre reclamo. L’Utente può proporre un reclamo all’autorità
              di controllo della protezione dei dati personali competente o
              agire in sede giudiziale.
            </li>
          </ul>
          <p></p>

          <h3 className="f3 lato fw4 mv2">
            Dettagli sul diritto di opposizione
          </h3>
          <p className="f4 lato fw4">
            Quando i Dati Personali sono trattati nell’interesse pubblico,
            nell’esercizio di pubblici poteri di cui è investito il Titolare
            oppure per perseguire un interesse legittimo del Titolare, gli
            Utenti hanno diritto ad opporsi al trattamento per motivi connessi
            alla loro situazione particolare.
          </p>
          <p className="f4 lato fw4">
            Si fa presente agli Utenti che, ove i loro Dati fossero trattati con
            finalità di marketing diretto, possono opporsi al trattamento senza
            fornire alcuna motivazione. Per scoprire se il Titolare tratti dati
            con finalità di marketing diretto gli Utenti possono fare
            riferimento alle rispettive sezioni di questo documento.
          </p>

          <h3 className="f3 lato fw4 mv2">Come esercitare i diritti</h3>
          <p className="f4 lato fw4">
            Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare
            una richiesta agli estremi di contatto del Titolare indicati in
            questo documento. Le richieste sono depositate a titolo gratuito e
            evase dal Titolare nel più breve tempo possibile, in ogni caso entro
            un mese.
          </p>

          <h3 className="f3 lato fw4 mv2">Difesa in giudizio</h3>
          <p className="f4 lato fw4">
            I Dati Personali dell’Utente possono essere utilizzati da parte del
            Titolare in giudizio o nelle fasi preparatorie alla sua eventuale
            instaurazione per la difesa da abusi nell'utilizzo di questa
            Applicazione o dei Servizi connessi da parte dell’Utente.
          </p>
          <p className="f4 lato fw4">
            L’Utente dichiara di essere consapevole che il Titolare potrebbe
            essere obbligato a rivelare i Dati per ordine delle autorità
            pubbliche.
          </p>

          <h3 className="f3 lato fw4 mv2">Informative specifiche</h3>
          <p className="f4 lato fw4">
            Su richiesta dell’Utente, in aggiunta alle informazioni contenute in
            questa privacy policy, questa Applicazione potrebbe fornire
            all'Utente delle informative aggiuntive e contestuali riguardanti
            Servizi specifici, o la raccolta ed il trattamento di Dati
            Personali.
          </p>

          <h3 className="f3 lato fw4 mv2">Log di sistema e manutenzione</h3>
          <p className="f4 lato fw4">
            Per necessità legate al funzionamento ed alla manutenzione, questa
            Applicazione e gli eventuali servizi terzi da essa utilizzati
            potrebbero raccogliere log di sistema, ossia file che registrano le
            interazioni e che possono contenere anche Dati Personali, quali
            l’indirizzo IP Utente.
          </p>

          <h3 className="f3 lato fw4 mv2">
            Informazioni non contenute in questa policy
          </h3>
          <p className="f4 lato fw4">
            Ulteriori informazioni in relazione al trattamento dei Dati
            Personali potranno essere richieste in qualsiasi momento al Titolare
            del Trattamento utilizzando gli estremi di contatto.
          </p>

          <h3 className="f3 lato fw4 mv2">Modifiche a questa privacy policy</h3>
          <p className="f4 lato fw4">
            Il Titolare del Trattamento si riserva il diritto di apportare
            modifiche alla presente privacy policy in qualunque momento dandone
            informazione agli Utenti su questa pagina e, se possibile, su questa
            Applicazione nonché, qualora tecnicamente e legalmente fattibile,
            inviando una notifica agli Utenti attraverso uno degli estremi di
            contatto di cui è in possesso il Titolare . Si prega dunque di
            consultare regolarmente questa pagina, facendo riferimento alla data
            di ultima modifica indicata in fondo.
          </p>
          <p className="f4 lato fw4">
            Qualora le modifiche interessino trattamenti la cui base giuridica è
            il consenso, il Titolare provvederà a raccogliere nuovamente il
            consenso dell’Utente, se necessario.
          </p>

          <p className="f4 lato fw4 mv4">Ultima modifica: 4 Giugno 2018</p>
        </div>
      </main>
    </Layout>
  )
}

export default Privacy
